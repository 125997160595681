import Gleap from 'gleap'
import { useRouter } from 'next/router'
import { destroyCookie, parseCookies } from 'nookies'
import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import withQueryClientProvider from 'src/hoc/with-query-client-provider'
import { refetchSubscriptionData } from '~/components/Subscription/useSubscriptionPlan'
import configuration from '~/configuration'
import { IUserInformation } from '~/core/@types/global'
import {
  COOKIE_PATH,
  SESSION_COOKIE_CURRENT_TENANT,
  SESSION_COOKIE_CURRENT_TENANT_EXT,
  SESSION_COOKIE_IS_DISABLE_SIDEBAR_ITEM,
  SESSION_COOKIE_ROLE_EXT,
  SESSION_COOKIE_USER,
  SESSION_EXPIRES_AT_COOKIE_NAME
} from '~/core/constants/cookies'
import {
  setSessionCookieClient,
  setSessionCookieExtClient
} from '~/core/middleware/save-session-cookie'
import useContextGraphQL from '~/core/middleware/use-context-graphQL'
import { Avatar } from '~/core/ui/Avatar'
import { Tooltip } from '~/core/ui/Tooltip'
import { DropdownMenu } from '~/core/ui/DropdownMenu'
import { LucideIconName } from '~/core/ui/IconWrapper'
import { cn } from '~/core/ui/utils'
import { catchErrorFromGraphQL } from '~/core/utilities/catch-api-error'
import {
  adminAndMemberCanAction,
  adminCanAction
} from '~/core/utilities/permission'
import QuerySelectTenantMutation from '~/lib/features/select-company/graphql/submit-mutation'
import { useSubmitCommon } from '~/lib/hooks/use-submit-graphql-common'
import useBoundStore from '~/lib/store'
import SubscriptionBadge, {
  SubscriptionBadgeOverlay,
  SubscriptionDayLeft
} from '../Subscription/SubscriptionBadge'
import SelectCompanyDropdown from './component/SelectCompanyDropdown'
import useSubscriptionPlan from '../Subscription/useSubscriptionPlan'
import { PLAN_FEATURE_KEYS } from '~/lib/features/settings/plans/utilities/enum'
import useDetectCompanyWithKind from '~/lib/hooks/use-detect-company-with-kind'
import { AGENCY_TENANT } from '~/core/constants/enum'
import { mappingCurrentTenantCookie } from '~/cookies/currentTenant'
import { mappingUserCookie } from '~/cookies/user'
import useReferralSetting from '~/lib/features/settings/referrals/hooks/useReferralSetting'
import usePermissionSetting from '~/lib/features/permissions/hooks/use-permission-setting'
import { PUBLIC_HELP_CENTER_URL, PUBLIC_APP_URL } from '~/core/constants/env'

const SidebarProfile: FC<{
  user: IUserInformation
  signOut: (value: object) => void
  expired?: boolean
  openNav?: boolean
}> = ({ user, signOut, expired, openNav }) => {
  const { t } = useTranslation()
  const { currentRole } = useBoundStore()
  const router = useRouter()
  const { clientGraphQL } = useContextGraphQL()
  const { trigger, isLoading } = useSubmitCommon(QuerySelectTenantMutation, {
    notUpdateLoadingAfterMutation: true
  })
  const setToast = useBoundStore((state) => state.setToast)
  const { actionAccessReferral } = usePermissionSetting()

  const cookies = parseCookies()
  let disabled = (cookies[SESSION_COOKIE_IS_DISABLE_SIDEBAR_ITEM] ||
    expired ||
    false) as boolean
  const getInfoName = () => {
    if (!user.fullName) return user.email
    return `${user.fullName}`
  }

  const {
    isFeatureEnabled,
    isUnLockFeature,
    data: dataTenantSettingPlan,
    showIconFeatureFromAdminSetting: onCheckFunc
  } = useSubscriptionPlan()
  const { dataReferral } = useReferralSetting({
    suspend:
      !dataTenantSettingPlan ||
      !isFeatureEnabled(PLAN_FEATURE_KEYS.referral) ||
      !isUnLockFeature(PLAN_FEATURE_KEYS.referral)
  })

  const { isCompanyKind } = useDetectCompanyWithKind({ kind: AGENCY_TENANT })

  const showIconFeatureFromAdminSetting = (featureKey: string) => {
    return onCheckFunc(featureKey, user?.ownTenant || false)
  }

  const menuSidebarItem: Array<{
    label?: string
    icon?: LucideIconName
    onClick?: (event: React.MouseEvent<HTMLElement>) => void
    isSwitchExtraRendered?: boolean
    disabled?: boolean
  }> = [
    ...(!isCompanyKind &&
    isFeatureEnabled(PLAN_FEATURE_KEYS.employee_profile) &&
    showIconFeatureFromAdminSetting(PLAN_FEATURE_KEYS.employee_profile)
      ? [
          {
            disabled,
            label: `${t('common:sidebar:settings:list:accountList:profiles')}`,
            icon: 'ClipboardList' as LucideIconName,
            onClick: () => router.push(configuration.path.settings.profiles)
          }
        ]
      : []),
    ...(isFeatureEnabled(PLAN_FEATURE_KEYS.referral) &&
    isUnLockFeature(PLAN_FEATURE_KEYS.referral) &&
    dataReferral?.values?.enabling
      ? [
          {
            disabled,
            label: `${t('common:sidebar:profile:dropdown:careerHub')}`,
            icon: 'FileBoxIcon' as LucideIconName,
            onClick: () => {
              if (actionAccessReferral) {
                alert('user have permission access career hub')
              } else {
                alert(
                  'user dont have permission access career hub, will navigate to login'
                )
              }
              // router.push(configuration.path.careerHub.login)
            }
          }
        ]
      : []),
    {
      disabled,
      label: `${t('common:sidebar:profile:dropdown:settings')}`,
      icon: 'Settings' as LucideIconName,
      onClick: () => router.push(configuration.path.settings.account)
    },
    {
      disabled,
      label: `${t('common:sidebar:yourCareerPage')}`,
      icon: 'Globe' as LucideIconName,
      onClick: () => {
        window.open(
          `${PUBLIC_APP_URL}${
            user.currentTenant?.canonicalUrl ||
            user.currentTenant?.careerSiteSettings?.canonical_url
          }`,
          '_blank'
        )
      }
    },
    {
      label: `${t('common:sidebar:profile:dropdown:switchCompany')}`,
      icon: 'RefreshCcw',
      isSwitchExtraRendered: true
    },
    {
      label: `${t('common:sidebar:profile:dropdown:helpCenter')}`,
      icon: 'HelpCircle',
      disabled: expired,
      onClick: () => {
        if (Gleap.getInstance().initialized) {
          Gleap.open()
        } else {
          window && window.open(PUBLIC_HELP_CENTER_URL)
        }
      }
    },
    ...(adminAndMemberCanAction(currentRole?.code)
      ? [
          {
            label: `${t('common:sidebar:profile:dropdown:downloadExtension')}`,
            icon: 'Download' as LucideIconName,
            onClick: () => {
              window.open(configuration.downloadExt, '_blank')
            }
          }
        ]
      : []),
    {
      label: `${t('common:sidebar:profile:dropdown:logOut')}`,
      icon: 'LogOut',
      onClick: () => signOut({})
    }
  ]

  const submitDataCallback = useCallback(
    async (tenantId: number) => {
      if (isLoading) {
        return
      }
      Gleap.clearIdentity()
      await trigger({
        tenantId: tenantId ? Number(tenantId) : undefined
      }).then(async (result) => {
        if (result.error) {
          return catchErrorFromGraphQL({
            error: result.error,
            page: configuration.path.selectCompany,
            setToast
          })
        }
        destroyCookie(null, SESSION_COOKIE_IS_DISABLE_SIDEBAR_ITEM, {
          path: COOKIE_PATH
        })

        const { selectTenant } = result.data
        if (selectTenant.currentTenant) {
          Gleap.identify(selectTenant?.user?.id, {
            name: selectTenant?.user?.fullName,
            email: selectTenant?.user?.email,
            // @ts-ignore
            companyId: selectTenant.currentTenant.id,
            // @ts-ignore
            companyName: selectTenant.currentTenant.name,
            plan: selectTenant.currentTenant.tenantPlan?.name,
            customData: {
              type: selectTenant?.user?.ownTenant
                ? `${selectTenant.currentTenant.companyKind}-owner`
                : `${selectTenant.currentTenant.companyKind}`,
              companyId: selectTenant.currentTenant.id,
              companyName: selectTenant.currentTenant.name
            }
          })
          const userCookie = mappingUserCookie({
            ...user,
            userTenants: selectTenant.user.userTenants,
            ownTenant: selectTenant.user.ownTenant,
            language: selectTenant.user.language
          })

          setSessionCookieClient(
            SESSION_COOKIE_USER,
            JSON.stringify(userCookie).toString(),
            Number(cookies[SESSION_EXPIRES_AT_COOKIE_NAME])
          )

          const obj = {
            ...selectTenant.currentTenant,
            careerSiteSettings: selectTenant.currentTenant.careerSiteSettings
          }
          const currentTenantObject = mappingCurrentTenantCookie(obj)

          setSessionCookieClient(
            SESSION_COOKIE_CURRENT_TENANT,
            JSON.stringify(currentTenantObject).toString(),
            Number(cookies[SESSION_EXPIRES_AT_COOKIE_NAME])
          )
          setSessionCookieExtClient(
            SESSION_COOKIE_CURRENT_TENANT_EXT,
            JSON.stringify(currentTenantObject).toString(),
            Number(cookies[SESSION_EXPIRES_AT_COOKIE_NAME])
          )
        }

        if (selectTenant.user.roles?.length) {
          const rolesObj = { data: selectTenant.user.roles }
          const currentRoleFilter = rolesObj.data.filter(
            (item: { id: string }) => item.id
          )
          const currentRoleName = currentRoleFilter.length
            ? currentRoleFilter?.[0]?.name
            : ''
          setSessionCookieExtClient(
            SESSION_COOKIE_ROLE_EXT,
            JSON.stringify(currentRoleName).toString(),
            Number(cookies[SESSION_EXPIRES_AT_COOKIE_NAME])
          )
        }

        await refetchSubscriptionData(clientGraphQL)

        return (window.location.href = configuration.path.default)
      })
    },
    [isLoading, trigger, setToast, cookies]
  )

  return (
    <div className="sidebar-profile h-[65px] border-t border-gray-100 px-2 py-2.5 dark:border-gray-700">
      <div className="ml-auto">
        <DropdownMenu
          sideOffset={8}
          menuClassName="w-[264px] z-[9999]"
          trigger={(open) => (
            <div
              className={cn(
                'flex flex-shrink-0 cursor-pointer items-center rounded px-1.5 py-2 hover:bg-gray-50',
                open ? 'bg-gray-50' : ''
              )}>
              <SubscriptionBadgeOverlay>
                <Avatar
                  color={user.defaultColour}
                  alt={getInfoName()}
                  size="md"
                  src={user.avatarVariants?.thumb?.url}
                />
              </SubscriptionBadgeOverlay>

              {openNav ? (
                <div className="mx-2">
                  <div className="line-clamp-1 text-sm font-medium text-gray-900">
                    {user?.fullName}
                  </div>
                </div>
              ) : null}
            </div>
          )}
          isWithHeader={true}
          isDisabledHeader={disabled}
          avatarProps={{
            color: user.defaultColour,
            src: user.avatarVariants?.thumb?.url,
            alt: getInfoName(),
            name: getInfoName(),
            email: user.currentTenant?.name,
            bottomContent: (
              <div className="mt-1 flex">
                <SubscriptionDayLeft />
              </div>
            ),
            onClick: () => router.push(configuration.path.settings.account)
          }}
          menu={menuSidebarItem}
          extraRendered={{
            title: `${t('common:sidebar:selectCompany')}`,
            content: (
              <SelectCompanyDropdown
                submitDataCallback={submitDataCallback}
                user={user}
              />
            )
          }}
        />
      </div>
    </div>
  )
}

export default withQueryClientProvider(SidebarProfile)
